<template>
  <div class="listDetails">
    <div class="banner"><img :src="banners" alt=""></div>
    <mainBox></mainBox>
    <div class="allDetails">
		<div class="allHeader_center">
			<span  class="titles">{{title}}</span>

			<div class="time">
				{{ time }}
				<img src="../assets/museumDetails/dotteLine1.png" alt="">
				<div>

				</div>
			</div>
		</div>
       
		<div class="listDetails_div" v-html="ht">

		</div>
		<div class="border">
			<img src="../assets/museumDetails/dotteLine1.png" alt="">
		</div>
		<Share :titles="title" :images="img"></Share>
	</div>
       
    <Footer></Footer>
    
  </div>
</template>

<script>
import Footer from '../components/footer'
import { fetch } from '../api/https';
import Share from '../components/share.vue';
import title from '../assets/intro/title.png';
import mainBox from '../components/main.vue';
// import 

export default {
  data() {
    return {
      id: "",
      time: "",
      ht:'',
      title:'',
      img:'',
      banners:''
    };
  },
  components:{
      Footer,
      Share,
      mainBox
  },
 
     created() {
    this.id = this.$route.query.id;
    this.requs()
    document.body.scrollTop  = 0;
  },
  mounted() {
    fetch('/getmenu')
          .then(res => {
			  console.log(res.data);
            this.banners = res.data[6].child[0].menu_img;
      })
      document.title = this.title
  },
  methods: {
    
    requs(){
      fetch(`/getnews_detail/${this.id}`).then((res) => {
        this.ht = res.data.news_content;
        this.title = res.data.news_title;
        document.title = res.data.news_title
        this.time = res.data.news_time
        let times = new Date(this.time*1000)
        var year = times.getFullYear()
        var   Month = times.getMonth() + 1
        var day = times.getDate()
        var tim = year + '-' +Month +'-'+day
        this.time = tim
        this.img = res.data.news_img

      }).catch((err) => {
        
      });
    }
  },
};
</script>

<style scoped>
.titles{
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 540;
  color: #333333;
}
.listDetails {
  height: auto;
  width: 100%;
  background-color: rgb(249, 249, 249);
  /* padding-top: 64px; */
}
.action {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  color: #1a1a1a;
font-size: 16px;
font-family: PingFangSC;
color: #1A1A1A;
margin-bottom: 48px;
}
.allDetails {
  padding: 0 100px;
  padding-top: 56px;
  padding-bottom: 68px;
  max-width: 1400px;
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
}
.allHeader_center{

  text-align: center;
}
.banner{
      width: 100%;
    height: 300px;
    margin: 0 auto;
}
.banner img{
    width: 100%;
    height: 300px;
    margin: 0 auto;
}
.border{
  padding-top: 41px;
  /* border-bottom: 1px solid #cccccc; */
  width: 1200px;
  margin: 0 auto;
}
.time {
    margin: 0 auto;
    /* margin-top: 10px; */
    width: 1200px;
  color: #cccccc;
  font-weight: 400;
  /* border-bottom: 1px solid #cccccc ; */
  padding: 19px 0 16px 0;
  /* line-height: 40px; */
  box-sizing: border-box;
}
.time img{
  width: 100%;
}
.div{
    width: 1200px;
    height: auto;
    margin: 0 auto;
    line-height: 36px;
    font-size: 20;
    text-align: left;
}
.div p{
    text-indent: 2em;

}
.foot{
    text-align: right;
    width: 1200px;
    line-height: 30px;
    margin: 0 auto;
    border-bottom: 1px solid #cccccc;
}
.foots{
    text-align: right;
    width: 1200px;
    font-size: 20px;
    line-height: 30px;
    margin: 0 auto;
    line-height: 180px;
}
.foots img{
    height: 30px;
    width: 30px;
    margin:0 10px ;
    float: right;
    /* margin-top: -25px; */
    margin-top: 75px;
}
@media screen  and (max-width: 1500px){
  .allDetails{
    width: 1266px;
    
  }
  .time img{
    width: 1100px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    margin-left: -25px;
  }
}
</style>
<style >
  .listDetails_div span{
  color:#666666 !important;
  line-height: 2 !important;
}
</style>